import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Input, Button, Flex, VStack, HStack, Image } from '@chakra-ui/react';
import { Icon } from '../../../../common/components/Icons';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '../../../../common/components/LoadingPage';
import { useProjectList } from '../../../../api/useProjects';
import { useDownloadVideo } from '../../../../features/DownloadVideo/useDownloadVideo';

interface FinalizeVideoProps {
  mediaId: string;
  onGenerateMore: () => void;
  onGenerate: () => void;
  updateProjectName: (newName: string) => Promise<void>;
}

export const FinalizeVideo: React.FC<FinalizeVideoProps> = ({
  mediaId,
  onGenerateMore,
  onGenerate,
  updateProjectName,
}) => {
  const [title, setTitle] = useState('');
  const [previewFilePath, setPreviewFilePath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { data: projects, refetch } = useProjectList();
  const { t } = useTranslation();
  const { downloadVideo, isDownloading } = useDownloadVideo();

  useEffect(() => {
    let isCancelled = false;
    const checkStatus = async () => {
      if (isCancelled) return;
      await refetch();
      const currentProject = projects?.find(project => project.medias.some(media => media.id === mediaId));

      const currentMedia = currentProject?.medias.find(media => media.id === mediaId);

      if (currentMedia?.status === 'Completed') {
        setTitle(currentProject?.project.name || 'Untitled');
        setPreviewFilePath(currentMedia.previewFilePath || '');
        setIsLoading(false);
      } else {
        setTimeout(checkStatus, 5000);
      }
    };

    if (isLoading) {
      checkStatus();
    }
    return () => {
      isCancelled = true;
    };
  }, [isLoading, projects, mediaId]);

  useEffect(() => {
    const generateVideo = async () => {
      setIsLoading(true);
      await onGenerate();
    };

    generateVideo();
  }, []);

  const handleDownload = async () => {
    const currentProject = projects?.find(project => project.medias.some(media => media.id === mediaId));
    const currentMedia = currentProject?.medias.find(media => media.id === mediaId);

    if (!currentMedia?.videoFilePath) {
      console.log('Error media empty');
      return;
    }

    try {
      const fileName = `${currentProject?.project.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.mp4`;
      await downloadVideo(currentMedia.videoFilePath, fileName);
      console.log('Video download success');
    } catch (error) {
      console.log(`Error download ${error}`);
    }
  };

  if (isLoading) {
    return <LoadingPage text={t('finalize.generating')} />;
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleTitleBlur = async () => {
    await updateProjectName(title);
  };

  return (
    <Box bg="#1A1A1E" border="1px solid #3D3D45" p={6}>
      <Flex>
        <Box flex="1" mr={6}>
          <Image src={previewFilePath} alt="Video preview" width="100%" height="auto" />
        </Box>
        <VStack align="stretch" flex="1" spacing={4}>
          <Heading size="lg" color="white">
            {t('finalize.title')}
          </Heading>
          <Box>
            <Text color="white" mb={2}>
              {t('finalize.videoTitle')}
            </Text>
            <Input
              value={title}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              bg="#27272A"
              border="1px solid #3D3D45"
              color="white"
            />
          </Box>
          <Box>
            <Text color="white" mb={2}>
              {t('finalize.description')}
            </Text>
            <Text color="gray.400">{t('finalize.descriptionText')}</Text>
          </Box>
          <HStack spacing={4} mt={4}>
            <Button
              leftIcon={<Icon iconCode={'Lucide:Download'} />}
              colorScheme="purple"
              flex="1"
              onClick={handleDownload}
              isLoading={isDownloading}>
              {isDownloading ? t('button.downloading') : t('button.download')}
            </Button>
            <Button
              leftIcon={<Icon iconCode={'Lucide:RefreshCw'} />}
              variant="outline"
              colorScheme="purple"
              flex="1"
              onClick={onGenerateMore}>
              {t('button.generateMore')}
            </Button>
          </HStack>
        </VStack>
      </Flex>
    </Box>
  );
};
