import React from 'react';
import { Text, Circle, Flex } from '@chakra-ui/react';
import { Icon } from '../Icons';
import { Card, CardProps } from './Card';
import { CardItem } from '../../models';

interface IconSelectionCardProps extends CardProps {
  item: CardItem;
  isSelected: boolean;
  onClick: () => void;
}

export const IconSelectionCard: React.FC<IconSelectionCardProps> = ({ item, isSelected, onClick, ...props }) => {
  return (
    <Card
      onClick={onClick}
      cursor="pointer"
      bg="#27272A"
      border="1px solid"
      borderColor={isSelected ? '#731EE3' : 'transparent'}
      p={4}
      textAlign="center"
      transition="all 0.2s"
      _hover={{ transform: 'scale(1.05)' }}
      {...props}>
      <Flex direction="column" align="center">
        <Circle
          size="60px"
          bg={isSelected ? '#731EE3' : 'linear-gradient(142.82deg, #554E5E 13.11%, #3A3541 87.34%)'}
          mb={2}>
          {item.iconCode && <Icon iconCode={item.iconCode} boxSize={6} color="white" />}
        </Circle>
        <Text color="white" fontWeight="bold">
          {item.name}
        </Text>
      </Flex>
    </Card>
  );
};
