import { useState } from 'react';
import { useGenerateMedia } from '../../../api/useMedia';

export interface SelectedData {
  niche: string | null;
  avatar: string | null;
  hooks: string | null;
  ctaLink: string | null;
}

export const useVideoCreation = () => {
  const [selectedData, setSelectedData] = useState<SelectedData>({
    niche: null,
    avatar: null,
    hooks: null,
    ctaLink: null,
  });
  const generateMedia = useGenerateMedia();

  const handleNicheSelection = (nicheIds: string) => {
    setSelectedData(prev => ({ ...prev, niche: nicheIds }));
  };

  const handleAvatarSelection = (avatarIds: string) => {
    setSelectedData(prev => ({ ...prev, avatar: avatarIds }));
  };

  const handleHookSelection = (hookId: string) => {
    setSelectedData(prev => ({ ...prev, hooks: hookId }));
  };

  const handleCTAChange = (ctaLink: string | null) => {
    setSelectedData(prev => ({ ...prev, ctaLink }));
  };

  const handleVideoGeneration = async (mediaId: string) => {
    try {
      await generateMedia.mutateAsync(mediaId);
      console.log('Video generated successfully with data:', selectedData);
    } catch (error) {
      console.error('Failed to generate media:', error);
    }
  };

  const resetVideoCreation = () => {
    setSelectedData({
      niche: null,
      avatar: null,
      hooks: null,
      ctaLink: null,
    });
  };

  return {
    selectedData,
    handleNicheSelection,
    handleAvatarSelection,
    handleHookSelection,
    handleCTAChange,
    handleVideoGeneration,
    resetVideoCreation,
  };
};
