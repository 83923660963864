export const mockPricingPlans = [
  {
    name: 'Free',
    price: '$0',
    features: ['12 video generations', '3 downloads per week'],
  },
  {
    name: 'Pro',
    price: '$39.97',
    features: ['30 video generations', '12 downloads per week'],
    recommended: true,
  },
  {
    name: 'Expert',
    price: '$99.97',
    features: ['Unlimited video generations', 'Unlimited downloads per week'],
  },
];
