import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface PricingModalContextType {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const PricingModalContext = createContext<PricingModalContextType | undefined>(undefined);

export const PricingModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const value: PricingModalContextType = useMemo(
    () => ({
      isOpen,
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [isOpen],
  );

  return <PricingModalContext.Provider value={value}>{children}</PricingModalContext.Provider>;
};

export const usePricingModal = () => {
  const context = useContext(PricingModalContext);
  if (context === undefined) {
    throw new Error('usePricingModal must be used within a PricingModalProvider');
  }
  return context;
};
