import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import DashboardPage from './pages/DashboardPage';
import { PricingModalProvider } from './features/Pricing/PricingModalProvider';
import { VideoCreatePage } from './pages/VideoCreatePage/VideoCreatePage';
import { Auth0Provider } from './auth/Auth0Provider';
import { withAuthGuard } from './auth/AuthGuard';
import { environment } from './config/environment';
import { ApiProvider } from './api/api.context';
import { ProjectDetailPage } from './pages/ProjectDetailPage/ProjectDetailPage';

const DashboardPageWithAuthGuard = withAuthGuard(DashboardPage);
const VideoCreatePageWithAuthGuard = withAuthGuard(VideoCreatePage);

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Auth0Provider>
        <ApiProvider baseUrl={environment.api.baseUrl}>
          <ChakraProvider>
            <PricingModalProvider>
              <Routes>
                <Route path="/" element={<DashboardPageWithAuthGuard />} />
                <Route path="/projects/:projectId" element={<ProjectDetailPage />} />
                <Route path="/create-video" element={<VideoCreatePageWithAuthGuard />} />
              </Routes>
            </PricingModalProvider>
          </ChakraProvider>
        </ApiProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
};

export default App;
