import React, { ReactElement } from 'react';
import { Box, Button, Flex, Heading, VStack } from '@chakra-ui/react';
import { Icon, LogoIcon } from '../Icons';
import { IconCode } from '../Icons/Icons';

export interface MenuItem {
  key: string;
  name: string;
  icon: IconCode;
  onClick: () => void;
}

export interface SidebarProps {
  header?: string;
  menuItems: MenuItem[];
  renderBeforeMenuItems?: () => ReactElement;
  renderAfterMenuItems?: () => ReactElement;
}

const Sidebar: React.FC<SidebarProps> = ({ header, menuItems, renderBeforeMenuItems, renderAfterMenuItems }) => {
  return (
    <Box bg="#1a1a1a" color="white" h="100vh" p={5} display="flex" flexDirection="column" overflowY="auto">
      <Flex alignItems="center" mb={5}>
        <LogoIcon boxSize="40px" mr={2} />
        {header && (
          <Heading size="md" color="white">
            {header}
          </Heading>
        )}
      </Flex>
      {renderBeforeMenuItems && renderBeforeMenuItems()}
      <VStack spacing={2} align="stretch" flex={1}>
        {menuItems.map((item: MenuItem) => (
          <Button
            key={item.key}
            leftIcon={<Icon iconCode={item.icon} />}
            variant="ghost"
            justifyContent="flex-start"
            w="100%"
            color="#7A8391"
            _hover={{ bg: '#27272A', color: 'white' }}
            onClick={item.onClick}>
            {item.name}
          </Button>
        ))}
      </VStack>
      {renderAfterMenuItems && renderAfterMenuItems()}
    </Box>
  );
};

export default Sidebar;
