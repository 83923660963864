import React from 'react';
import { Text, Circle, Flex, Box } from '@chakra-ui/react';
import { Icon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { Step } from './Steps';

interface StepItemProps {
  step: Step;
  isActive: boolean;
  isCompleted: boolean;
  onClick: () => void;
}

export const StepItem: React.FC<StepItemProps> = ({ step, isActive, isCompleted, onClick }) => {
  const { t } = useTranslation();
  return (
    <Flex align="center" flexDirection="row" onClick={onClick} cursor="pointer">
      <Circle size="40px" bg={isActive ? 'purple.500' : 'gray.700'} color="white" fontWeight="bold">
        {isCompleted ? <Icon iconCode={'Lucide:Check'} /> : step.index}
      </Circle>
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        ml="12px">
        <Text color="white" fontWeight="bold">
          {t('steps.step', { number: step.index })}
        </Text>
        <Text color={isActive ? 'purple.500' : 'gray.400'}>{step.label}</Text>
      </Box>
    </Flex>
  );
};
