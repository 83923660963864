import { useDeleteProject } from '../../../api/useProjects';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export const useProjectDeletion = () => {
  const deleteProjectMutation = useDeleteProject();
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const handleDeleteSelected = async (selectedProjects: string[]) => {
    setIsDeleting(true);
    try {
      await Promise.all(selectedProjects.map(projectId => deleteProjectMutation.mutateAsync(projectId)));
      setIsDeleting(false);
      navigate('/');
      return true;
    } catch (error) {
      console.error('Error deleting projects:', error);
      setIsDeleting(false);
      return false;
    }
  };

  return { handleDeleteSelected, isDeleting };
};
