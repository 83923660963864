import React from 'react';
import { Box, Text, Badge, Progress, Button } from '@chakra-ui/react';
import { Icon } from '../../common/components/Icons';
import { useTranslation } from 'react-i18next';
import { usePricingModal } from '../Pricing/PricingModalProvider';
import { useUserProfile } from '../../api/useUserProfile';
import { LoadingPage } from '../../common/components/LoadingPage/LoadingPage';

export const UserInfoBox: React.FC = () => {
  const { t } = useTranslation();
  const { open } = usePricingModal();
  const { data: userInfo, isLoading } = useUserProfile();

  if (isLoading) {
    return <LoadingPage text={t('loading.userInfo')} />;
  }

  if (!userInfo) {
    console.log('Error loading user info');
    return <Text>{t('loading.userInfo')}</Text>;
  }

  const attemptsLeft = 5;
  const maxAttempts = 10;
  return (
    <Box border="1px solid #3D3D45" borderRadius="md" py={4} mb={5}>
      <Box px={4} mb={5} borderBottom="1px solid #3D3D45">
        <Text fontSize="sm" mb={2}>
          {userInfo.user.email}
        </Text>
      </Box>
      <Box px={4}>
        <Badge
          border="1px solid"
          bg="linear-gradient(180deg, rgba(207, 170, 255, 0.15) 6.25%, rgba(152, 105, 213, 0.15) 100%)"
          color="#9747FF"
          px={2}
          py={1}
          borderRadius="full"
          fontSize="xs"
          display="inline-flex"
          alignItems="center"
          mb={1}>
          <Icon iconCode={'Lucide:Zap'} mr={1} boxSize={3} />
          {t('sidebar.freeLabel')}
        </Badge>
        <Text fontSize="14px" color="white" mb={3}>
          {t('sidebar.startForFree')}
        </Text>
        <Progress
          value={(attemptsLeft / maxAttempts) * 100}
          size="sm"
          mb={1}
          background="#27272A"
          sx={{
            '& > div:first-of-type': {
              background: 'linear-gradient(90deg, #40107D 0%, #731EE3 100%)',
            },
          }}
          borderRadius="4px"
        />
        <Text fontSize="xs" color="#a0a0a0" mb={3}>
          {t('sidebar.creditsLeft', { attemptsLeft, maxAttempts })}
        </Text>
        <Button
          variant="outline"
          background="linear-gradient(180deg, #731EE3 0%, #40107D 100%)"
          color="white"
          border="none"
          size="sm"
          w="100%"
          onClick={open}
          _hover={{
            background: 'linear-gradient(180deg, #8A31FF 0%, #5A1EAE 100%)',
          }}>
          {t('sidebar.addCredits')}
        </Button>
      </Box>
    </Box>
  );
};
