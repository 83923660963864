import { useState } from 'react';
import { ProjectWithMedia } from '../../../common/models';
import { useNavigate } from 'react-router-dom';

export const useProjectSelection = (projects: ProjectWithMedia[]) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const navigate = useNavigate();

  const handleSelectProject = (projectId: string) => {
    setSelectedProjects(prev =>
      prev.includes(projectId) ? prev.filter(id => id !== projectId) : [...prev, projectId],
    );
  };

  const handleSelectAll = () => {
    setSelectedProjects(selectedProjects.length === projects.length ? [] : projects.map(project => project.project.id));
  };

  const toggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
    if (isSelectionMode) {
      setSelectedProjects([]);
    }
  };

  const handleCardClick = (project: ProjectWithMedia) => {
    if (isSelectionMode) {
      handleSelectProject(project.project.id);
    } else {
      const isDraft = project.medias.some(media => media.status === 'Draft');
      if (isDraft) {
        navigate('/create-video', {
          state: {
            projectId: project.project.id,
            mediaId: project.medias[0]?.id,
          },
        });
      } else {
        navigate(`/projects/${project.project.id}`);
      }
    }
  };

  return {
    selectedProjects,
    isSelectionMode,
    handleSelectProject,
    handleSelectAll,
    handleCardClick,
    toggleSelectionMode,
  };
};
