import { useState, useEffect } from 'react';
import { PlanProps } from '../../../common/components/Card/PlanCard';
import { mockPricingPlans } from '../../../mocks/mockPricingPlans';

export const usePricingPlansList = () => {
  const [pricingPlansList, setPricingPlansList] = useState<PlanProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        setIsLoading(true);
        setPricingPlansList(mockPricingPlans);
      } catch (err) {
        setError(new Error(`An error occurred while loading pricing plan data. Error: ${String(err)}`));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPricingPlans();
  }, []);

  return { pricingPlansList, isLoading, error };
};
