import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Flex, Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '../../common/components/LoadingPage';
import { AppMainLayout } from '../../AppMainLayout';
import { useCreateProject } from '../../features/ProjectCreation/hooks/useCreateProject';
import { ProjectDetails } from './components/ProjectDetails';
import { useProjectDetail } from './hooks/useProjectDeteil';
import { ConfirmationDialog } from '../../common/components/ConfirmationDialog/ConfirmationDialog';

export const ProjectDetailPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { createProject } = useCreateProject();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    project,
    title,
    isLoading,
    error,
    isDownloading,
    isDeleting,
    handleTitleChange,
    handleTitleBlur,
    handleDownload,
    handleDelete,
  } = useProjectDetail(projectId || '');

  if (isLoading) {
    return <LoadingPage />;
  }
  if (error || !project) {
    return <Box>Error loading project</Box>;
  }
  return (
    <AppMainLayout
      renderAfterMenuItems={() => (
        <Button colorScheme="purple" mt="auto" w="100%" onClick={() => createProject('New Project')}>
          {t('button.createProject')}
        </Button>
      )}>
      <Box bg="#1A1A1E" border="1px solid #3D3D45" p={6}>
        <Flex>
          <Box flex="1" mr={6}>
            <img
              src={project.medias[0]?.previewFilePath || ''}
              alt="Video preview"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
          <Box flex="1">
            <Heading size="lg" color="white" mb={4}>
              {t('projectDetail.title')}
            </Heading>
            <ProjectDetails
              project={project}
              title={title}
              onTitleChange={e => handleTitleChange(e.target.value)}
              onTitleBlur={handleTitleBlur}
              onDownload={handleDownload}
              onDelete={onOpen}
              isDownloading={isDownloading}
              isDeleting={isDeleting}
            />
          </Box>
        </Flex>

        <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleDelete}
          title={t('projectDetail.deleteConfirmation')}
          description={t('projectDetail.deleteWarning')}
          confirmText={t('button.delete')}
          cancelText={t('button.cancel')}
          confirmButtonColor="red"
          cancelButtonColor="purple"
        />
      </Box>
    </AppMainLayout>
  );
};
