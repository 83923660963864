import React, { ReactElement } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { Background } from '../Background';
import { MenuItem, Sidebar } from '../Sidebar';
import { usePricingModal } from '../../../features/Pricing/PricingModalProvider';
import { PricingModal } from '../../../features/Pricing/PricingModal';
import { MainContent } from './components/MainContent';
import { MobileMenuButton } from './components/MobileMenuButton';
import { useIsMobile } from '../../hooks/useIsMobile';

export interface MainLayoutProps {
  children: React.ReactNode;
  header?: string;
  menuItems: MenuItem[];
  renderBeforeMenuItems?: () => ReactElement;
  renderAfterMenuItems?: () => ReactElement;
}

export const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  header,
  menuItems,
  renderBeforeMenuItems,
  renderAfterMenuItems,
}) => {
  const { isOpen: isPricingOpen, close: closePricing } = usePricingModal();
  const { isOpen: isSidebarOpen, onToggle: toggleSidebar } = useDisclosure({ defaultIsOpen: true });
  const isMobile = useIsMobile();
  const sidebarWidth = '280px';

  return (
    <Background>
      <Flex h="100vh" overflow="hidden">
        {isMobile && <MobileMenuButton isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />};
        <Box
          position={isMobile ? 'fixed' : 'sticky'}
          zIndex="sticky"
          left={0}
          top={0}
          width={sidebarWidth}
          minHeight="100vh"
          transform={isMobile && !isSidebarOpen ? 'translateX(-100%)' : 'translateX(0)'}
          transition="transform 0.3s">
          <Sidebar
            header={header}
            menuItems={menuItems}
            renderBeforeMenuItems={renderBeforeMenuItems}
            renderAfterMenuItems={renderAfterMenuItems}
          />
        </Box>
        <MainContent isSidebarOpen={isSidebarOpen} sidebarWidth={sidebarWidth}>
          {children}
        </MainContent>
      </Flex>
      <PricingModal isOpen={isPricingOpen} onClose={closePricing} />
    </Background>
  );
};
