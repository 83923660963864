import React from 'react';
import { SimpleGrid, Box, Button, Heading, Checkbox, useDisclosure } from '@chakra-ui/react';
import { VideoCard } from '../../../common/components/Card/VideoCard';
import { ProjectWithMedia } from '../../../common/models';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/components/Icons';
import { useCreateProject } from '../../../features/ProjectCreation/hooks/useCreateProject';
import { useProjectSelection } from '../hooks/useProjectSelection';
import { useProjectDeletion } from '../hooks/useProjectDeletion';
import { ProjectControlPanel } from '../components/ProjectControlPanel';
import { LoadingPage } from '../../../common/components/LoadingPage';
import { ConfirmationDialog } from '../../../common/components/ConfirmationDialog/ConfirmationDialog';

interface VideoGridProps {
  projects: ProjectWithMedia[];
}

export const VideoGridDashboard: React.FC<VideoGridProps> = ({ projects }) => {
  const { t } = useTranslation();
  const { createProject } = useCreateProject();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    selectedProjects,
    isSelectionMode,
    handleSelectProject,
    handleCardClick,
    handleSelectAll,
    toggleSelectionMode,
  } = useProjectSelection(projects);
  const { handleDeleteSelected, isDeleting } = useProjectDeletion();

  const onDeleteSelected = () => {
    handleDeleteSelected(selectedProjects);
  };

  if (isDeleting) {
    return <LoadingPage />;
  }

  return (
    <Box height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={6}>
        <Box>
          <Heading color="white" mb={6}>
            {t('dashboard.projects')}
          </Heading>
          <ProjectControlPanel
            isSelectionMode={isSelectionMode}
            toggleSelectionMode={toggleSelectionMode}
            handleSelectAll={handleSelectAll}
            handleDeleteSelected={onOpen}
            selectedProjectsCount={selectedProjects.length}
            isDeleting={isDeleting}
          />
        </Box>
        <Button
          colorScheme="purple"
          onClick={() => createProject('New Project')}
          leftIcon={<Icon iconCode="Lucide:Download" />}>
          {t('sidebar.createVideo')}
        </Button>
      </Box>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 6 }} spacing={4} width="100%">
        {projects.map((video: ProjectWithMedia) => (
          <Box
            key={video.project.id}
            position="relative"
            onClick={() => handleCardClick(video)}
            cursor={'pointer'}
            _hover={isSelectionMode ? { opacity: 0.8 } : {}}>
            {isSelectionMode && (
              <Checkbox
                position="absolute"
                top={2}
                left={2}
                zIndex={1}
                isChecked={selectedProjects.includes(video.project.id)}
                onChange={() => handleSelectProject(video.project.id)}
                colorScheme="purple"
              />
            )}
            <VideoCard
              key={video.project.id}
              title={video.project.name}
              thumbnailUrl={video.medias[0]?.previewFilePath || ''}
              createdAt={video.project.createdAt}
            />
          </Box>
        ))}
      </SimpleGrid>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onDeleteSelected}
        title={t('projectDetail.deleteConfirmation')}
        description={t('projectDetail.deleteWarning')}
        confirmText={t('button.delete')}
        cancelText={t('button.cancel')}
        confirmButtonColor="red"
        cancelButtonColor="purple"
      />
    </Box>
  );
};
