import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StepItem } from './StepItem';

export interface Step {
  index: number;
  labelKey: string;
  label?: string;
}

interface StepsProps {
  steps: Step[];
  currentStep: number;
  onChange: (step: number) => void;
}

export const Steps: React.FC<StepsProps> = ({ steps, currentStep, onChange }) => {
  const { t } = useTranslation();

  return (
    <Flex justify="space-between" mb={6} align="center" width="100%" p={6}>
      {steps.map((step, index) => (
        <React.Fragment key={step.index}>
          <StepItem
            key={step.index}
            step={{ ...step, label: t(step.labelKey) }}
            isActive={currentStep === step.index}
            isCompleted={currentStep > step.index}
            onClick={() => onChange(step.index)}
          />
          {index < steps.length - 1 && <Box flex={1} height="2px" bg="gray.700" mx={2} />}
        </React.Fragment>
      ))}
    </Flex>
  );
};
