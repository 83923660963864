import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Media } from '../../common/models';
import { useDateFormatter } from '../../common/hooks/useDateFormatter';

interface DetailItem {
  label: string;
  value: string | null;
}

export const useProjectDetailItems = (media: Media | null): DetailItem[] => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();

  return useMemo(() => {
    if (!media) {
      return [];
    }

    const details: DetailItem[] = [
      { label: t('projectDetail.updatedAt'), value: media.updatedAt ? formatDate(media.updatedAt) : null },
      { label: t('projectDetail.niche'), value: media.niche?.name || null },
      { label: t('projectDetail.avatar'), value: media.avatar?.name || null },
      { label: t('projectDetail.hook'), value: media.hookStyle?.name || null },
    ];

    return details.filter((item): item is DetailItem & { value: string } => item.value != null);
  }, [media, t, formatDate]);
};
