import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface BackgroundProps extends BoxProps {
  children: React.ReactNode;
}

export const Background: React.FC<BackgroundProps> = ({ children, ...rest }) => (
  <Box
    backgroundImage="url('/images/background.png')"
    backgroundSize="cover"
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    minHeight="100vh"
    width="100%"
    {...rest}>
    {children}
  </Box>
);

export default Background;
