import React from 'react';
import { StepLayout } from '../../../../common/components/Layout/StepLayout';
import { HookStyle } from '../../../../common/models';
import { GridList } from '../../../../common/components/GridList';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { IconSelectionCard } from '../../../../common/components/Card/IconSelectionCard';

interface HookSelectionProps {
  hookStyles: HookStyle[];
  selectedHookId: string | null;
  onSelect: (ids: string) => void;
  onContinue: () => void;
}

export const HookSelection: React.FC<HookSelectionProps> = ({ hookStyles, selectedHookId, onSelect, onContinue }) => {
  const { t } = useTranslation();
  return (
    <StepLayout title={t('hook.title')} isNextDisabled={selectedHookId === null} onContinue={onContinue}>
      <Text color="gray.400" mb={4}>
        {t('hook.textSelectedItems')}
      </Text>
      <GridList
        items={hookStyles}
        selectedIds={selectedHookId !== null ? [selectedHookId] : []}
        onChangeSelected={indexes => onSelect(indexes[0])}
        Card={IconSelectionCard}
      />
    </StepLayout>
  );
};
