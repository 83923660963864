import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EmptyDashboard } from './EmptyDashboard/EmptyDashboard';
import { VideoGridDashboard } from './VideoGridDashboard/VideoGridDashboard';
import { useCreateProject } from '../../features/ProjectCreation/hooks/useCreateProject';
import { AppMainLayout } from '../../AppMainLayout';
import { useProjectList } from '../../api/useProjects';
import { LoadingPage } from '../../common/components/LoadingPage';

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const { createProject } = useCreateProject();
  const { data: projects, isLoading } = useProjectList();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <AppMainLayout
      renderAfterMenuItems={() => (
        <Button colorScheme="purple" mt="auto" w="100%" onClick={() => createProject('New Project')}>
          {t('button.createProject')}
        </Button>
      )}>
      <Box flex="1" p={8}>
        {!projects || !projects.length ? <EmptyDashboard /> : <VideoGridDashboard projects={projects} />}
      </Box>
    </AppMainLayout>
  );
};

export default DashboardPage;
