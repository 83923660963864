import React, { useCallback, useEffect, useState } from 'react';
import { Box, Input, Flex, Text } from '@chakra-ui/react';
import { Steps } from '../../common/components/Steps';
import { steps } from '../../config/stepsConfig';
import { NicheSelectionContainer } from './containers/NicheSelectionContainer/NicheSelectionContainer';
import { HookSelectionContainer } from './containers/HookSelectionContainer/HookSelectionContainer';
import { FinalizeVideoContainer } from './containers/FinalizeVideoContainer/FinalizeVideoContainer';
import { useVideoCreation } from './hooks/useVideoCreation';
import { useSteps } from './hooks/useSteps';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useProject } from './hooks/useProject';
import { useUpdateMediaData } from './hooks/useUpdateMediaData';
import { AppMainLayout } from '../../AppMainLayout';

export const VideoCreatePage: React.FC = () => {
  const location = useLocation();
  const mediaId = location.state?.mediaId;
  const projectId = location.state?.projectId;
  const { project: project, updateProjectName, isUpdating } = useProject(projectId);
  const { currentStep, changeStep, restartStep, nextStep } = useSteps(steps.length);
  const [localProjectName, setLocalProjectName] = useState('Untitled');
  const { selectedData, handleNicheSelection, handleHookSelection, handleVideoGeneration, resetVideoCreation } =
    useVideoCreation();
  const updateMediaData = useUpdateMediaData(selectedData);
  const { t } = useTranslation();

  useEffect(() => {
    if (project) {
      setLocalProjectName(project.project.name);
    }
  }, [project]);

  useEffect(() => {
    const updateMedia = async () => {
      if (mediaId && currentStep > 1) {
        try {
          await updateMediaData();
          console.log('Media data updated after selection change');
        } catch (error) {
          console.error('Failed to update media data:', error);
        }
      }
    };
    updateMedia();
  }, [currentStep]);

  const handleGenerateVideo = useCallback(async () => {
    if (mediaId) {
      try {
        await updateMediaData();
        console.log('Media data updated before video generation');
        handleVideoGeneration(mediaId);
      } catch (error) {
        console.error('Failed to update media data before video generation:', error);
      }
    }
  }, [mediaId, updateMediaData, handleVideoGeneration]);

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalProjectName(e.target.value);
  };

  const handleProjectNameBlur = () => {
    if (project && localProjectName !== project.project.name) {
      updateProjectName(localProjectName);
    }
  };

  return (
    <AppMainLayout>
      <Box height="100%" width="100%">
        <Flex justifyContent="center" alignItems="center" mb={4} px={4} py={2} bg="#1A1A1E">
          <Input
            textAlign="end"
            value={localProjectName}
            onChange={handleProjectNameChange}
            onBlur={handleProjectNameBlur}
            variant="unstyled"
            fontWeight="bold"
            color="white"
            _hover={{ bg: '#2D2D34' }}
            _focus={{ bg: '#2D2D34', boxShadow: 'none' }}
            px={2}
            py={1}
            borderRadius="md"
            maxWidth="300px"
            mr={3}
            isDisabled={isUpdating}
          />
          <Text color="gray.500" fontSize="sm">
            {isUpdating ? t('createVideo.saving') : t('createVideo.autoSaved')}
          </Text>
        </Flex>
        <Steps steps={steps} currentStep={currentStep} onChange={changeStep} />
        {currentStep === 1 && (
          <NicheSelectionContainer
            nicheIdSelected={selectedData.niche}
            onContinue={nextStep}
            onNicheSelect={handleNicheSelection}
          />
        )}
        {currentStep === 2 && (
          <HookSelectionContainer
            hookStyleId={selectedData.hooks}
            nicheId={selectedData.niche}
            onContinue={nextStep}
            onHookStyleSelect={handleHookSelection}
            avatarId={selectedData.avatar}
          />
        )}
        {currentStep === 3 && (
          <FinalizeVideoContainer
            mediaId={mediaId}
            onGenerateMore={() => {
              restartStep();
              resetVideoCreation();
            }}
            onGenerate={handleGenerateVideo}
            updateProjectName={updateProjectName}
          />
        )}
      </Box>
    </AppMainLayout>
  );
};
