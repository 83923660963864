import React, { PropsWithChildren } from 'react';
import { useMenuItems } from './config/menuConfig';
import { MainLayout } from './common/components/Layout/MainLayuot';
import { UserInfoBox } from './features/UserInfo/UserInfoBox';
import { useTranslation } from 'react-i18next';

export interface AppMainLayoutProps extends PropsWithChildren {
  renderAfterMenuItems?: () => React.ReactElement;
}

export const AppMainLayout = ({ renderAfterMenuItems, children }: AppMainLayoutProps) => {
  const { t } = useTranslation();
  const menuItems = useMenuItems();

  return (
    <MainLayout
      header={t('sidebar.appName', { defaultValue: 'Printybot.io' })}
      menuItems={menuItems}
      renderBeforeMenuItems={() => <UserInfoBox />}
      renderAfterMenuItems={renderAfterMenuItems}>
      {children}
    </MainLayout>
  );
};
