import React from 'react';
import { VStack, Box, Text, Input, HStack, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ProjectWithMedia } from '../../../common/models';
import { ProjectDetailItem } from './ProjectDetailItem';
import { useProjectDetailItems } from '../../../features/ProjectDetails/useProjectDetailItems';
import { Icon } from '../../../common/components/Icons';
import { useDateFormatter } from '../../../common/hooks/useDateFormatter';

interface ProjectDetailsProps {
  project: ProjectWithMedia;
  title: string;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTitleBlur: () => void;
  onDownload: () => void;
  onDelete: () => void;
  isDownloading: boolean;
  isDeleting: boolean;
}

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  project,
  title,
  onTitleChange,
  onTitleBlur,
  onDownload,
  onDelete,
  isDownloading,
  isDeleting,
}) => {
  const { t } = useTranslation();
  const formatDate = useDateFormatter();
  const detailItems = useProjectDetailItems(project.medias[0] || null);

  return (
    <VStack align="stretch" spacing={4}>
      <Box>
        <Text color="white" mb={2}>
          {t('projectDetail.videoTitle')}
        </Text>
        <Input
          value={title}
          onChange={onTitleChange}
          onBlur={onTitleBlur}
          bg="#27272A"
          border="1px solid #3D3D45"
          color="white"
        />
      </Box>
      <ProjectDetailItem label={t('projectDetail.createdAt')} value={formatDate(project.project.createdAt)} />
      {detailItems.map(
        (item, index) => item.value && <ProjectDetailItem key={index} label={item.label} value={item.value} />,
      )}
      <HStack spacing={4} mt={4}>
        <Button
          leftIcon={<Icon iconCode={'Lucide:Download'} />}
          colorScheme="purple"
          flex="1"
          onClick={onDownload}
          isLoading={isDownloading}
          loadingText={t('button.downloading')}>
          {t('button.download')}
        </Button>
        <Button
          leftIcon={<Icon iconCode={'Lucide:Trash'} />}
          colorScheme="red"
          flex="1"
          onClick={onDelete}
          isLoading={isDeleting}>
          {t('button.delete')}
        </Button>
      </HStack>
    </VStack>
  );
};
