import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import { PlanCard } from '../../common/components/Card/PlanCard';
import { useTranslation } from 'react-i18next';
import { usePricingPlansList } from './hooks/usePricingPlansList';
import { LoadingPage } from '../../common/components/LoadingPage';

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PricingModal: React.FC<PricingModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { pricingPlansList, isLoading, error } = usePricingPlansList();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent bg="#1A1A1A" color="white" maxWidth="1000px">
        <ModalHeader fontSize="3xl" textAlign="center" pt={8}>
          {t('pricing.title')}
        </ModalHeader>
        <ModalCloseButton size="lg" />
        <ModalBody pb={12}>
          {isLoading ? (
            <Flex justifyContent="center" alignItems="center" height="200px">
              <LoadingPage size="xl" />
            </Flex>
          ) : error ? (
            <Text color="red.500" textAlign="center">
              {error.message}
            </Text>
          ) : (
            <Flex justifyContent="space-between" mb={12}>
              {pricingPlansList.map((plan, index) => (
                <PlanCard key={index} {...plan} isSecondStyle={index === 1} />
              ))}
            </Flex>
          )}
          <VStack spacing={6} align="center" maxWidth="700px" mx="auto">
            <Text fontSize="2xl" fontWeight="bold" color="yellow.400">
              ★ ★ ★ ★ ★
            </Text>
            <Text textAlign="center" color="gray.300" fontSize="lg">
              {t('pricing.comment')}
            </Text>
            <HStack>
              <Avatar size="md" name="Siddharth Gopi" bg="orange.500">
                SG
              </Avatar>
              <VStack align="start" spacing={0}>
                <Text fontWeight="bold" color="white" fontSize="lg">
                  Siddharth Gopi
                </Text>
                <Text fontSize="md" color="gray.400">
                  Co-founder at Tophire.co
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PricingModal;
