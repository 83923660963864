import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const LogoIcon: React.FC<IconProps> = props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M0.306396 18.5711C0.412219 19.0923 0.544893 19.6135 0.707576 20.13L28.7633 7.45337C27.8835 6.00186 26.7858 4.73199 25.5302 3.67218L0.306396 18.5711Z"
      fill="#8A31FF"
    />
    <path
      d="M30.7881 18.1762C31.0755 16.5383 31.1024 14.8404 30.8386 13.1457L1.48145 22.1185C1.71994 22.6271 1.98371 23.1167 2.27117 23.5874L30.7897 18.1762H30.7881Z"
      fill="#8A31FF"
    />
    <path
      d="M19.743 0.592233C16.6568 -0.285939 13.2736 -0.218023 10.0405 1.00131C3.6832 3.39733 -0.176971 9.52084 0.00624457 15.9508L19.743 0.592233Z"
      fill="#8A31FF"
    />
    <path
      d="M28.2894 24.2982L3.86646 25.7576C8.00618 30.4596 14.7725 32.3628 20.9813 30.0221C24.0628 28.8596 26.5568 26.8221 28.2894 24.2982Z"
      fill="#8A31FF"
    />
  </Icon>
);
