import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { usePricingModal } from '../features/Pricing/PricingModalProvider';

import { MenuItem } from '../common/components/Sidebar';
import { useTranslation } from 'react-i18next';

export const useMenuItems = (): MenuItem[] => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { open } = usePricingModal();
  const { t } = useTranslation();

  return [
    {
      key: 'home',
      name: t('sidebar.home'),
      icon: 'Lucide:House',
      onClick: () => navigate('/'),
    },
    {
      key: 'billing',
      name: t('sidebar.billing'),
      icon: 'Lucide:IdCard',
      onClick: open,
    },
    {
      key: 'settings',
      name: t('sidebar.settings'),
      icon: 'Lucide:Package',
      onClick: logout,
    },
  ];
};
