import React from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/components/Icons';

interface ProjectControlPanelProps {
  isSelectionMode: boolean;
  toggleSelectionMode: () => void;
  handleSelectAll: () => void;
  handleDeleteSelected: () => void;
  selectedProjectsCount: number;
  isDeleting: boolean;
}

export const ProjectControlPanel: React.FC<ProjectControlPanelProps> = ({
  isSelectionMode,
  toggleSelectionMode,
  handleSelectAll,
  handleDeleteSelected,
  selectedProjectsCount,
  isDeleting,
}) => {
  const { t } = useTranslation();

  return (
    <HStack spacing={4}>
      <Button
        colorScheme="purple"
        onClick={toggleSelectionMode}
        isDisabled={isDeleting}
        aria-label={isSelectionMode ? t('dashboard.cancelSelection') : t('dashboard.selectProjects')}>
        <Icon iconCode={isSelectionMode ? 'Lucide:X' : 'Lucide:Check'} />
      </Button>
      {isSelectionMode && (
        <>
          <Button
            colorScheme="purple"
            onClick={handleSelectAll}
            aria-label={t('dashboard.selectAll')}
            isDisabled={isDeleting}>
            <Icon iconCode="Lucide:CheckSquare" />
          </Button>
          <Button
            colorScheme="red"
            onClick={handleDeleteSelected}
            isDisabled={selectedProjectsCount === 0 || isDeleting}
            isLoading={isDeleting}
            aria-label={t('button.delete')}>
            <Icon iconCode="Lucide:Trash" />
          </Button>
        </>
      )}
    </HStack>
  );
};
