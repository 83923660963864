import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { Icon } from '../../Icons';

interface MobileMenuButtonProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <IconButton
      aria-label="Toggle menu"
      colorScheme="purple"
      icon={isSidebarOpen ? <Icon iconCode="Lucide:X" /> : <Icon iconCode="Lucide:Menu" />}
      onClick={toggleSidebar}
      position="fixed"
      top={4}
      left={4}
      transform={!isSidebarOpen ? 'translateX(0%)' : 'translateX(210px)'}
      transition="transform 0.3s ease"
      zIndex="overlay"
    />
  );
};
