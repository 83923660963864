import React from 'react';
import { VStack, Text, Badge, Button, HStack } from '@chakra-ui/react';
import { Icon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { Card, CardProps } from './Card';

export interface PlanProps {
  name: string;
  price: string;
  features: string[];
}

interface PlanCardProps extends PlanProps {
  isSecondStyle?: boolean;
}

export const PlanCard: React.FC<PlanCardProps> = ({ name, price, features, isSecondStyle = false }) => {
  const { t } = useTranslation();
  const cardVariant: CardProps['variant'] = isSecondStyle ? 'pricing-second' : 'pricing';

  return (
    <Card variant={cardVariant} width="300px" position="relative">
      <VStack align="stretch">
        <VStack
          p={6}
          width="100%"
          borderBottom={isSecondStyle ? '2px solid #5F19BA' : '1px solid #3D3D45'}
          justifyContent="space-between"
          alignItems="flex-start">
          <Text fontWeight="bold" fontSize="2xl" color="white">
            {name}
          </Text>
          {isSecondStyle && (
            <Badge
              bg="linear-gradient(180deg, rgba(207, 170, 255, 0.15) 6.25%, rgba(152, 105, 213, 0.15) 100%)"
              color="#9747FF"
              border="1px solid"
              borderColor="linear-gradient(170.54deg, #9747FF 10.56%, rgba(151, 71, 255, 0.4) 92.86%)"
              px={3}
              py={1}
              borderRadius="full">
              <Icon iconCode="Lucide:Zap" mr={1} boxSize={3} />
              {t('pricing.recommended')}
            </Badge>
          )}
        </VStack>
        <VStack p={6} width="100%" align="stretch">
          <Text fontSize="4xl" fontWeight="bold" mb="24px" color="white">
            {price}
          </Text>
          {features.map((feature, index) => (
            <HStack key={index} spacing={4} mb="24px">
              <Text color="green.500" fontSize="xl">
                ✓
              </Text>
              <Text color="gray.300" fontSize="md">
                {feature}
              </Text>
            </HStack>
          ))}
          <Button bg="purple.500" width="100%" color="white" size="lg" _hover={{ bg: 'purple.600' }}>
            {t('button.buy')}
          </Button>
        </VStack>
      </VStack>
    </Card>
  );
};
