import React from 'react';
import { Box } from '@chakra-ui/react';

interface MainContentProps {
  children: React.ReactNode;
  isSidebarOpen: boolean;
  sidebarWidth: string;
}

export const MainContent: React.FC<MainContentProps> = ({ children, isSidebarOpen, sidebarWidth }) => (
  <Box
    ml={{ base: 0, md: isSidebarOpen ? sidebarWidth : '0' }}
    flex="1"
    overflowY="auto"
    h="100vh"
    transition="margin-left 0.3s">
    <Box height="100%" p={{ base: 4, md: 8 }}>
      {children}
    </Box>
  </Box>
);
