import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { UniversalButton } from '../UniversalButton';
import { Icon } from '../Icons/Icons';

interface StepLayoutProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  isNextDisabled: boolean;
  onContinue: () => void;
}

export const StepLayout: React.FC<StepLayoutProps> = ({ title, description, children, isNextDisabled, onContinue }) => {
  return (
    <Box
      bg="#1A1A1E"
      border="1px solid #3D3D45"
      p={6}
      borderRadius="8px"
      height="80%"
      flexDirection="column"
      display="flex"
      justifyContent="space-between">
      <Box p={6} flex="1" overflowY="auto">
        <Heading size="lg" color="white" mb="12px">
          {title}
        </Heading>
        {description && (
          <Text color="gray.400" mb={4}>
            {description}
          </Text>
        )}
        {children}
      </Box>
      <UniversalButton
        buttonTextKey="button.continue"
        isDisabled={isNextDisabled}
        onClick={onContinue}
        colorScheme="purple"
        rightIcon={<Icon iconCode={'Lucide:Zap'} />}
        mt={4}
        alignSelf="flex-end"
      />
    </Box>
  );
};
