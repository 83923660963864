import { useState } from 'react';

export const useSteps = (totalSteps: number) => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    handleStepChange(currentStep + 1);
  };

  const changeStep = (step: number) => {
    if (step > currentStep) {
      console.warn(`Cannot jump to future step: ${step}. Current step is ${currentStep}.`);
      return;
    }
    handleStepChange(step);
  };

  const restartStep = () => {
    handleStepChange(1);
  };

  const handleStepChange = (step: number) => {
    if (step < 1 || step > totalSteps) {
      console.error(`Invalid step: ${step}. Step must be between 1 and ${totalSteps}.`);
      return;
    }
    setCurrentStep(step);
  };

  return {
    currentStep,
    changeStep,
    restartStep,
    nextStep,
  };
};
